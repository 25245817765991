

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, LinearProgress, Fab, Box, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
// redux action
import {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
} from 'actions';

import Auth from '../../Auth/Auth';
const auth = new Auth();


class Signin extends Component {
	//constructor
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {
				blankEmail: false,
				invalidEmail: false,
				blankPassword: false
			},
			value: 0
		}
	}


	/**
	 * Function to detect email changes
	 */
	onEmailChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankEmail = false;
		fieldValidationErrors.invalidEmail = false;
		
		this.props.onEmailChanged(e.target.value);
	}

	/**
	 * Function to detect login password changes
	 */
	onPasswordChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankPassword = false;
		this.setState({ formErrors: fieldValidationErrors });
		this.props.onPasswordChanged(e.target.value);
	}

	/**
	* Function is use for check the email validation.
	*/
	validateEmail(email) {
		let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		return emailValid;
	}

	/**
	 * Function to show error
	 */
	renderError() {
		if (this.props.error) {
			return (
				<div style={{ backgroundColor: 'white' }}>
					<TextField>{this.props.error}</TextField>
				</div>
			);
		}
	}


	onForgotPassword() {
		this.props.history.push('/forgot-password');
	}

	loginAuth0() {
		auth.login();
	}

	async onUserLogin() {
		const { email, password } = this.props;
		let fieldValidationErrors = this.state.formErrors;
		if (email === "") { fieldValidationErrors.blankEmail = true; }
		if (password === "") { fieldValidationErrors.blankPassword = true; }
		if (!this.validateEmail(email)) { fieldValidationErrors.invalidEmail = true; }
		await this.setState({
			formErrors: fieldValidationErrors
		})
		if (email !== "" && password !== "") {
			this.login(email, password);
		}
	}
	login(email, password ) {

	
		this.setState({ submitted: true });
		if (email && password) {
			this.props.signinUserWithJwt(this.props, this.props.history)
		}
	}
	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {

		const { blankEmail, blankPassword, invalidEmail } = this.state.formErrors;
		const { email, password, error } = this.props;

		return (
			<div>

				<div className="session-wrapper">
					<Grid container justify="center" alignItems="center">
						<Grid item xs={12} sm={12} md={6} lg={4} className="login-wrap">
							<div className="login-wrapper text-center" >
								<div className="w-100">
									<div className="session-logo">
										TURBA DATA
									</div>
									<form className="login-form">
										<Box mb={3}>
											<TextField
												required
												fullWidth
												type="email"
												name="email"
												label="Email-Adresse"
												placeholder="Geben Sie bitte Ihre Email-Adresse ein."
												className=""
												value={email}
												onChange={(email) => this.onEmailChanged(email)}
												error={blankEmail || invalidEmail || error ? true : false}
											/>
											{blankEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>E-Mail darf nicht leer sein.</Box>
											}
											{!blankEmail && invalidEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>
													Die E-Mail-Adresse ist schlecht formatiert.</Box>
											}
										</Box>
										<Box mb={3}>
											<TextField
												required
												fullWidth
												id="login-password"
												label="Passwort"
												placeholder="Bitte geben Sie Ihr Login-Passwort ein."
												className=""
												type="password"
												value={password}
												error={blankPassword || error ? true : false}
												onChange={this.onPasswordChanged.bind(this)}
											/>
											{blankPassword &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Passwort kann nicht leer sein.												</Box>
											}
										</Box>
										<Box mb="40px" pt="20px">
											<Button
												color="primary"
												className="btn-block blockBtn w-100"
												variant="contained"
												size="large"
												onClick={() => this.onUserLogin()}
											>
												Anmelden
											</Button>
										</Box>
										<Box fontSize="subtitle2.fontSize">
											<Box style={{ cursor: 'pointer' }} color="primary.main" onClick={() => this.onForgotPassword()}>Passwort vergessen?</Box>
										</Box>


									</form>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={8} style={{ backgroundImage: "url(" + require('assets/Images/session1bg.jpg') + ")", backgroundSize: 'cover', backgroundPosition: 'center right' }} className="img-session">
							<div className="login-content">
								<Box fontSize="h1.fontSize" fontWeight="h1.fontWeight" mb={4} color="common.white">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
								</Box>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ authUser, settings }) => {
	const { loading, email, password, error } = authUser;
	const { isDarkModeActive } = settings;
	return { loading, email, password, error };
};

export default connect(mapStateToProps, {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
})(Signin);
