/**
 * Courses Routing File
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
     AsyncOfficerDetails
 } from 'routes';
 
 const Officers = ({ match }) => (
     <Switch>  
         <Route path={`${match.url}/details`} component={AsyncOfficerDetails}></Route>		

     </Switch>
 )
 export default Officers;