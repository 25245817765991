import React, { Component } from 'react';

import { CustomCard } from 'components/GlobalComponents';
import { Divider, Avatar } from '@material-ui/core';
import { Grid, Box, Typography } from '@material-ui/core';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';



class SearchResult extends Component {
    constructor(props) {
        super();
        this.state = {
            country: props.country

        }
    }
    categoryDeatils(array) {
        let text = ""
        array.map((value, i) => {
            text = text + value.category_name + ', '
        })

        return text

    }
    redirectToYellowPageDetails(id) {
        window.open('/app/yellowPage/details/' + this.props.country + '?id=' + id)
    }
    
    render() {
        return (
            <Grid item xs={12} sm={12} md={8}>
                <Box className={`custom-table-wrap`}>
                    {this.props.YellowPgLoader ?
                        <div className="card-search">
                            <div className="card-content">
                                <div className="div-align-center">
                                    <img alt="No search results" src={require("assets/Images/Images/avatars/no_search_results.svg")} className="no-search-img" />
                                </div>
                                <p className="center-align">Diese Suchanfrage enthält keine Filter. Bitte fügen Sie entsprechende Suchfilter Ihrer Anfrage hinzu.
                                </p>
                            </div>
                        </div>
                        :
                        this.props.YellowPgLoader === false && this.props.resultLoader === true ?
                            <div className="card-search">
                                <div className="card-content">
                                    <div className="div-align-center">

                                        <ThreeDots
                                            height={80}
                                            width={80}
                                            color="black"
                                            visible={this.props.resultLoader}

                                        />
                                    </div>
                                </div>
                            </div>

                            : this.props.resultData.length == 0 && this.props.YellowPgLoader == false ?
                                <div className="card-search">
                                    <div className="card-content">
                                        <div className="div-align-center">
                                            <img alt="No search results" src={require("assets/Images/Images/avatars/no_search_results.svg")} className="no-search-img" />
                                        </div>
                                        <p className="center-align">Leider ergab Ihre Suchanfrage keine Treffer.
                                        </p>
                                    </div>
                                </div>

                                :
                                <CustomCard title={this.props?.resultData?.length + ' Suchergebnisse'} showDivider={true} >
                                    {this.props.initialSelectedValue == "Gelbe Seiten" ?

                                        this.props.resultData.map((element, index) => {


                                            return (<Box className="user-activity" key={index}>
                                                <Link  onClick={() => this.redirectToYellowPageDetails(element.ID ? element.ID : element.company_id)}>   <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>
                                                        <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                            <Box>
                                                                <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                            </Box>
                                                            <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                                <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                                    {element.company_name ? element.company_name : element.title}</Box>
                                                                <Typography variant="subtitle2"> | {element?.address_attributes?.city}</Typography>
                                                            </Box>
                                                            <Typography variant="body2" color="textPrimary">{this.categoryDeatils(element.category_attributes)}</Typography>


                                                        </Box>
                                                    </Box></Link>
                                                <Divider />
                                            </Box>)

                                        }) : <>
                                            <Box className="user-activity">  <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                <Box>
                                                    <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                </Box>
                                                <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                    <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                        <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                        <Typography variant="subtitle2"> | test</Typography>
                                                    </Box>
                                                    <Typography variant="body2" color="textPrimary">test</Typography>


                                                </Box>
                                            </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box className="user-activity">

                                                <Box display="flex" p={{ xs: 2, md: 3 }}>
                                                    <Box>
                                                        <Avatar alt="img" src={require(`assets/Images/company.png`)} style={{ width: '50px', height: '45px' }} />
                                                    </Box>
                                                    <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">

                                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" mb={1}>
                                                            <Box pr="5px" className="user-name text-primary" component="span" fontWeight={500}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Box>
                                                            <Typography variant="subtitle2"> | test</Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textPrimary">test</Typography>


                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                        </>
                                    }




                                </CustomCard>
                    }

                </Box>
            </Grid >)
    }
}



export default (SearchResult);