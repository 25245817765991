
export const watchListService = {
   getRadarsByCustomer,
   deleteCompanyRadar,
   getMarkedRadars,
   addCompaniestoRadar

};

function getRadarsByCustomer(customer_id) {
   const requestOptions = {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/companyRadar/getRadarsByCustomer/${customer_id}`, requestOptions)
      .then(handleResponse)

}

// delete company radar
function deleteCompanyRadar(radar_id) {
   const requestOptions = {
      method: 'Delete',
      headers: {
         'Content-type': 'application/json',
      }
   };

   return fetch(`${process.env.REACT_APP_API_URL}/companyRadar/delete/${radar_id}`, requestOptions).then(handleResponse);

}

function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         if (response.status === 401) {
            // auto logout if 401 response returned from api
            // logout();
            localStorage.removeItem('user_id');
            // location.reload(true);
         }

         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

function getMarkedRadars(customer_id) {
   const requestOptions = {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/companyRadar/getMarkedRadars/${customer_id}`, requestOptions)
      .then(handleResponse)

}
function addCompaniestoRadar(object) {
   const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: object
   };

   return fetch(`${process.env.REACT_APP_API_URL}/companyRadar/addcompanies`, requestOptions)
      .then(handleResponse)

}
