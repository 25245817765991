export default {
   "sidebar.dashboard": "Dashboard",
   "sidebar.package": "Package",
   "sidebar.notifications": "Notifications",
   "sidebar.statistics": "Statistics",
   "sidebar.developers": "Developers",
   "sidebar.apiKeys": "API keys",
   "sidebar.documentation": "Documentation",
   "sidebar.search": "Search",
   "sidebar.customers": "Customers",
   "sidebar.companies": "Companies",
   "sidebar.products": "Products",
   "sidebar.categories": "Categories",
   "sidebar.invoices": "Invoices",
   "sidebar.users": "Benutzerliste",
   "sidebar.persons": "Persons",
   "sidebar.shop": "Shop",
   "widgets.salutation": "Hello",
   "widgets.welcome": "Welcome",
   "widgets.titleError": "Please enter the title.",
   "widgets.priceNetError": "Please enter the price (net).",
   "widgets.categoryError": "Please enter the category.",
   "widgets.products": "Products",
   "widgets.title": "Title",
   "widgets.category": "Categorie",
   "widgets.productDescription": "Prodcut description",
   "widgets.image": "Image",
   "widgets.price_net": "Price (Nett)",
   "widgets.price_brut": "Price (brut)",
   "widgets.tva": "UST",
   "widgets.discount": "Discount",
   "widgets.submit": "Submit",
   "widgets.annuler": "Annuler",
   "widgets.companies": "Companies",
   "widgets.customer": "Customer",
   "widgets.invoices": "Invoices",
   "widgets.invoice": "Invoice",
   "widgets.users": "Users list",
   "widgets.Bankdaten": "Bank data",
   "widgets.ContactPerson": "Contact person",
   "widgets.company": "Company",
   "widgets.date": "Date",
   "widgets.status": "Status",
   "widgets.requests":"Requests",
   
   "component.email": "Email",
   

   "component.firstName": "First name",
   "component.lastName": "Last name",
   "component.salutation": "Saluation",
   "component.general": "General",
   "component.account": "Account",
   "component.password": "Password",
   "component.update": "Update",
   "component.settings": "Settings",
   "component.newPassword": "New password",
   "component.retypePassword": "Password confirm",
   "component.passwordError": "Pasword length must be more then 6 character",
   "component.passwordMatchError": "Confirm password is required",
   "component.invoice": "Invoice",
   "component.addUser": "Add a user",
   "component.edit": "Edit",
   "component.delete": "Delete"
}