/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


//  Dashboard 
const AsyncDashboard1Component = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncUsersComponent = Loadable({
	loader: () => import("routes/Users/Users"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("routes/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("routes/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("routes/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWatchList = Loadable({
	loader: () => import("routes/Companies/WatchList"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncCompanyDetails = Loadable({
	loader: () => import("routes/Companies/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncOfficerDetails = Loadable({
	loader: () => import("routes/Officers/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncOfficer = Loadable({
	loader: () => import("routes/Companies/Details/Officers"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncSearch = Loadable({
	loader: () => import("routes/Search/Search"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncCompanyLeiDetails = Loadable({
	loader: () => import("routes/Companies/Details/CompanyLeiDetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncNEWCompanies = Loadable({
	loader: () => import("routes/Companies/New"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncYPDetails = Loadable({
	loader: () => import("routes/YellowPage/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncExportList = Loadable({
	loader: () => import("routes/Export/Lists"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncExportListDetails =Loadable({
	loader: () => import("routes/Export/ListDetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
export {
	AsyncDashboard1Component,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncUsersComponent,
	AsyncWatchList,
	AsyncCompanyDetails,
	AsyncOfficerDetails,
	AsyncSearch,
	AsyncOfficer,
	AsyncCompanyLeiDetails,
	AsyncNEWCompanies,
	AsyncYPDetails,
	AsyncExportList,
	AsyncExportListDetails
};