/**
 * Contacts tab section
*/
import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import NewWatchListCompanies from './../../../routes/Companies/WatchList/newWatchListCompanies';

const styles = theme => ({
	appWrap: {
		boxShadow: "none",
	},
	toolbar: {
		padding: '10px 0 0',
		marginLeft: -12,
		marginRight: -12,
	}
});

function TabPanel(props) {
	const { children, value, index, dir, ...other } = props;
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
			dir={dir}
		>
			{value === index && <Box py={3} px={2}>{children}</Box>}
		</Typography>
	);
}

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	};
}



class NotificationsTabs extends Component {

	constructor(props) {
		super(props);

	}


	render() {
		return (
			<Box width="100%" className="notify-tabs">
				<p>No content</p>
			</Box>
		);
	}
}

export default withStyles(styles)(withTheme(NotificationsTabs));