import appLocaleData from 'react-intl/locale-data/de';
import enMessages from '../locales/de_DE';

const DeLang = {
    messages: {
        ...enMessages
    },
    locale: 'de-DE',
    data: appLocaleData
};
export default DeLang;