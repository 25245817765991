export const ApiHraService = {
    newCompanies,
    searchCompany,
    advancedSearchCompany,
    getCompany,
    getCompanyStatic,
    getCompanyNetwork,
    getcompanyBranches,
    getCompanyContact,
    getNotices,
    downloadDocument,
    getNotice,
    getOfficer,
    searchOfficer,
    getCompanyOfficers,
    getOfficernotices,
    getOfficernetwork,
    getDocumentList,
    getInsolvency,
    getCompanyLei,
    getCompanyYields,
    export_Companies
};
function export_Companies(type,object){
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',

        },
        body:object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/export/${type}`, requestOptions).then(handleResponse);

}
function getOfficernotices(officer_id) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/notices/${officer_id}`, requestOptions).then(handleResponse);

}
function getOfficer(officer_id) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/${officer_id}`, requestOptions).then(handleResponse);

}
function getCompanyOfficers(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/company/${company_number}`, requestOptions).then(handleResponse);

}
function getCompanyStatic(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/bilan/statistic/${company_number}`, requestOptions).then(handleResponse);
}

function getCompanyYields(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/bilan/yields/${company_number}`, requestOptions).then(handleResponse);
}

function downloadDocument(object, document_id) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',

        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/document/${document_id}/base64`, requestOptions).then(handleResponse);



}
function getInsolvency(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/insolvency/${company_number}`, requestOptions).then(handleResponse);

}
function getNotices(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/notices/company/${company_number}`, requestOptions).then(handleResponse);

}
function getNotice(notice_id) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/notices/${notice_id}`, requestOptions).then(handleResponse);

}
function getOfficernetwork(officer_id) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/network/${officer_id}`, requestOptions).then(handleResponse);

}
function newCompanies() {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/new`, requestOptions).then(handleResponse);

}
function advancedSearchCompany(object) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(object)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/advancedSearch`, requestOptions).then(handleResponse);
}

function searchCompany(company_name) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/searchCompany/${company_name}`, requestOptions).then(handleResponse);


}
function getcompanyBranches() {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/branches`, requestOptions).then(handleResponse);


}

function searchOfficer(officer_name) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/searchOfficer/${officer_name}`, requestOptions).then(handleResponse);


}
function getCompany(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/${company_number}`, requestOptions).then(handleResponse);
}
function getCompanyLei(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/lei/${company_number}`, requestOptions).then(handleResponse);
}

function getCompanyContact(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/contact/${company_number}`, requestOptions).then(handleResponse);

}
function getCompanyNetwork(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/network/${company_number}`, requestOptions).then(handleResponse);

}

function getDocumentList(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/document/${company_number}`, requestOptions).then(handleResponse);

}
function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.ok) {

            return data;
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    })
}
