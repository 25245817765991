/**
 * Courses Routing File
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {

    AsyncYPDetails,



} from 'routes';

const YellowPage = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/details/:country`} component={AsyncYPDetails}></Route>
    </Switch>
)
export default YellowPage;