

export const ExportListService = {
    getExportListByCustomer,
    createList,
    advanced_search,
    getListDetails,
    export_List,

};
function advanced_search(object){
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',

        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/advancedSearch`, requestOptions).then(handleResponse);

}

function getExportListByCustomer(customer_id) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/list/${customer_id}`, requestOptions).then(handleResponse);

}
function createList (object){
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',

        },
        body: object
    }
    return fetch(`${process.env.REACT_APP_API_URL}/list/create`, requestOptions).then(handleResponse);

}

function getListDetails(id){
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/list/details/${id}`, requestOptions).then(handleResponse);

}

function export_List(type,id){
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/list/export/${type}/${id}`, requestOptions).then(handleResponse);

}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.ok) {

            return data;
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    })
}
