export const AddressService = {
    getCitiesList
};
// generate invoice
function getCitiesList(bundesland_id,city_name) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/cities/${bundesland_id}/${city_name}`, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                localStorage.removeItem('user_id');
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
