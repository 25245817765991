export const YellowPageService = {

    search,
    getYPCompany,
    advancedSearch

};






function search(search_key_word) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/yellowPage/search/${search_key_word}`, requestOptions).then(handleResponse);


}
function advancedSearch(object) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },

        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/yellowPage/advancedSearch`, requestOptions).then(handleResponse);


}
function getYPCompany(country, yp_ID) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/yellowPage/${country}/${yp_ID}`, requestOptions).then(handleResponse);


}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.ok) {

            return data;
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    })
}
