/**
 * Courses Routing File
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
    AsyncExportList,
    AsyncExportListDetails
 
 
 } from 'routes';
 
 const Export = ({ match }) => (
     <Switch>
         <Route path={`${match.url}/List`} component={AsyncExportList}></Route>
         <Route path={`${match.url}/List-details`} component={AsyncExportListDetails}></Route>
        </Switch>
 )
 export default Export;