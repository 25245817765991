/*
 * Task List Widget
 */
import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
    Box, List, ListItem,  Avatar, IconButton,Button,
} from '@material-ui/core';
import moment from 'moment';
import Dragula from 'react-dragula';

import { userService, watchListService } from '_services';
import { ThreeDots } from 'react-loader-spinner';
const styles = theme => ({

    navList: {
        borderBottom: `1px Solid ${theme.palette.divider}`,
        transition: 'all 0.3s ease-out',
        '& .content-wrap': {
            position: 'relative',
            width: 'calc(100% )',
        },
        '& .content-text': {
            width: 'calc(100% - 130px)',

        },
        '& .task-meta': {
            width: 130,

        },
        '& .task-action': {
            position: 'absolute',
            right: 0,
            opacity: 0
        },
        '&:hover': {
            '& .icon-wrap': {
                opacity: 1
            },
            '& .task-meta': {
                opacity: 0
            },
            '& .task-action': {
                opacity: 1,
            },
        }
    },
    padY: {
        paddingTop: 10,
        paddingBottom: 0,
    },
    avatr: {
        fontSize: 15,
        width: 36,
        height: 36,
    },
});

class newWatchListCompanies extends Component {
    state = {
        watchListData: [],
        snackbar: false,
        snackbarMessage: '',
        loader:false

    }
    getRadarsByCustomer() {
        this.setState({ loader: true })

        watchListService.getRadarsByCustomer(userService.getId()).then(result => {
            this.setState({ watchListData: result.radars ,loader:false})
        })
    }
    componentDidMount() {
        this.getRadarsByCustomer()
    }
    dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
            let options = {};
            Dragula([componentBackingInstance], options);
        }
    }

    redirectToDetails(id) {
        window.location.href = '/app/companies/details?id=' + id
    }


    render() {
        const { classes } = this.props;
        return (
            this.state.loader ? 
            <div style={{ justifyContent: 'center', display: 'flex' }}>
            <ThreeDots
                height={80}
                width={80}
                color="black"
                visible={this.state.loadData}

            />
        </div>: 
            <List component="nav" className={clsx(`${classes.padY} container todo-list-ul`)} ref={this.dragulaDecorator}>
                {this.state.watchListData.length > 0 ?
                <>
                    <Fragment>
                        {
                            this.state.watchListData.slice(-this.props.startIndex, this.state.watchListData.length).map((data, index) => (
                                <ListItem key={index} disableRipple className={classes.navList} button>
                                    <div className="w-100">
                                        <Box display="flex" justifyContent="flex-start" alignItems="center">

                                            <Box className="content-wrap" display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between" alignItems="center" onClick={() => this.redirectToDetails(data?.Company?.company_number)}>
                                                <Box className="content-text" >{data?.Company?.name}</Box>
                                                <Box className="task-meta" pl={2} display="flex" justifyContent="flex-end" alignItems="center">
                                                    {data.lastUpdate && <Box color={data.lastUpdate} pr={1} fontSize="body1.fontSize">{moment(data.lastUpdate).format("DD.MM.YYYY")}</Box>}
                                                   
                                                </Box>
                                                <Box className="task-action" display="flex" justifyContent="flex-end" alignItems="center">

                                                    <IconButton size="small" onClick={() => this.redirectToDetails(data?.Company?.company_number)}>
                                                        <Box component="span" fontSize={{ xs: 14, sm: 20 }} color="primary.main" className="material-icons-outlined">visibility</Box>
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </div>
                                </ListItem>
                            ))
                        }
                    </Fragment>
                    {this.state.watchListData.length > 9 ?
                    <ListItem component="div" className="top-dropdown-menu--item d-block margin-btn text-center">
								<Button variant="contained" className='primary-bg-btn compose-btn' onClick={()=>window.location.href="/app/companies/watchList"} >
									watch list
								</Button>
							</ListItem>
                            :<></>}
                            </>

                    :
                    <Box fontSize="h5.fontSize">
                        Liste ist leer

                    </Box>
                }

            </List>
            

        )
    }
}
export default withStyles(styles)(newWatchListCompanies);