/**
 * Global Search Component
*/
/*eslint-disable*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Tooltip, IconButton, Box, TextField, Icon, Typography, Paper, Grid, Container } from '@material-ui/core';
import { CustomCard } from 'components/GlobalComponents';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { ThreeDots } from 'react-loader-spinner';

import { ApiHraService, YellowPageService } from '_services';
const WAIT_INTERVAL = 1000
const ENTER_KEY = 13
const styles = theme => ({
	Paper: {
		padding: '0.75rem',
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'&:first-child': {
			paddingTop: '24px',
		},
		'&:last-child': {
			paddingBottom: '30px',
		}
	},
	inputBar: {

		width: 'calc(100% - 40px)',
		'& .MuiInputBase-root': {
			'&:before, &:after': {
				display: 'none',
			}
		}

	},
	closeIcon: {
		width: 40,
		color: theme.palette.primary.main,
	},
	spanColor: {
		color: theme.palette.text.secondary,
		display: "inline-block",
		margin: 0,
		fontSize: ".8rem"

	},
	IconSize: {
		marginRight: "0.5rem",
	},
	searchIcon: {
		marginRight: '0.5rem',
		color: "#29303b",
		padding: 'inherit'
	},
	linkSize: {
		fontSize: "1.3rem"
	},
	sIcon: {
		transform: "scale(0.9)",
		fontSize: "35px"
	},
	spanPosition: {
		position: "relative",
		top: "-5px"
	}
});

class GlobalSearch extends Component {
	constructor() {
		super()
		this.state = {
			searchResult: false,
			searchData: [],
			officers: [],
			yellowPageData: [],
			value: '',
			companyLoader: false

		};
	}

	UNSAFE_componentWillMount() {
		this.updateDimensions();
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}

	updateSearch = e => {
		clearTimeout(this.timer)
		let searchString = e.target.value;
		this.setState({ value: searchString }, () => {
			if (searchString == '') {
				this.setState({ searchResult: false })
				this.setState({ companyLoader: false })

			} else
		{	

			this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)

}

		})

	}
	handleKeyDown = e => {
		if (e.keyCode === ENTER_KEY) {
			clearTimeout(this.timer)
			this.triggerChange()
		}
	}

	triggerChange = () => {
		let { value } = this.state;
		this.setState({ searchResult: true, companyLoader: true });
			ApiHraService.searchCompany(value).then(result => {
				this.setState({ searchData: result.companies, companyLoader: false });
			});
			ApiHraService.searchOfficer(value).then(result => {
				this.setState({ officers: result.officers });
			});
			YellowPageService.search(value).then(result => {
				this.setState({ yellowPageData: result.YellowPageData });
			});
		
	}

	redirectToCompanyDetails(id) {
		window.location.href = '/app/companies/details?id=' + id
	}
	redirectToOfficerDetails(id) {
		window.location.href = '/app/officers/details?id=' + id
	}
	changeSearchResult() {
		this.setState({ searchResult: false, value: '' })
		this.props.showSearchBar()
	}
	redirectToYellowPageDetails(id) {
		window.location.href = '/app/yellowPage/details/DE?id=' + id
	}
	render() {
		const { className } = this.props;
		const { classes } = this.props;
		const { searchResult, searchData, yellowPageData, value, officers } = this.state;

		return (
			<div className={className}>
				<Box className={classes.inputBar}>
					<TextField
						fullWidth
						id="standard-basic"
						onChange={this.updateSearch}
						onKeyDown={this.handleKeyDown}

						placeholder="Unternehmen suchen & finden"
						value={this.state.value} />
				</Box>
				<Tooltip title="Close" placement="bottom">
					<IconButton
						className={classes.closeIcon}
						size="small"
						onClick={this.props.showSearchBar}
					>
						<Icon style={{ transform: 'scale(0.9)' }}>close</Icon>
					</IconButton>
				</Tooltip>
				<div className="search-overlay-wrap">
					<Container>



						{this.state.companyLoader ?
							<>
								<Paper className={classes.Paper} square>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={12} md={12} >
											<CustomCard  >
												<Box >

													<Typography className="ml-1 " variant="body2">
														<Tooltip title="Search" placement="bottom">
															<IconButton
																className={classes.searchIcon}
																size="medium"
															>
																<Icon className={classes.sIcon}>search</Icon>
															</IconButton>
														</Tooltip>
														<a className={classes.linkSize}>{this.state.value}</a></Typography>

												</Box>
											</CustomCard>
										</Grid>
									</Grid>
								</Paper>
								<div style={{ justifyContent: 'center', display: 'flex' }}>
									<ThreeDots
										height={80}
										width={80}
										color="black"
										visible={this.state.companyLoader}

									/>
								</div>
							</>
							: <></>}{searchResult ?
								<>
									<Paper className={classes.Paper} square>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={12} md={12} >
												<CustomCard  >
													<Box >

														<Typography className="ml-1 " variant="body2">
															<Tooltip title="Search" placement="bottom">
																<IconButton
																	className={classes.searchIcon}
																	size="medium"
																>
																	<Icon className={classes.sIcon}>search</Icon>
																</IconButton>
															</Tooltip>
															<a className={classes.linkSize}>{this.state.value}</a></Typography>

													</Box>
												</CustomCard>
											</Grid>
										</Grid>
									</Paper>
									<Paper className={classes.Paper} square>
										<Grid container spacing={3}>

											{this.state.searchData.length > 0 &&
												<Grid item xs={12} sm={this.state.officers.length > 0 ? 6 : 12} md={this.state.officers.length > 0 ? 6 : 12} >
													<Typography className="ml-1 margin-text" variant="body2">
														<BusinessIcon className={classes.IconSize} />
														<span className={classes.spanPosition}>Firmen</span>
													</Typography>
													<CustomCard  >
														<Box >
															<ul >
																{
																	searchData.map((data, i) => (
																		<li key={i}>
																			<Box >
																				<Link to={{
																					pathname: '/app/companies/details?id=' + data.company_number

																				}} onClick={() => this.redirectToCompanyDetails(data.company_number)}>


																					{data.name}<div className="separation-line hide-on-small-only"></div><span className={`${classes.spanColor} hide-on-small-only`} > {data.all_attributes._registerArt} {data.all_attributes._registerNummer}</span>

																				</Link>
																			</Box>
																		</li>
																	))
																}
															</ul>
														</Box>
													</CustomCard>
												</Grid>
											}
											{this.state.officers.length > 0 &&

												<Grid item xs={12} sm={6} md={6} >
													<Typography className="ml-1 margin-text" variant="body2">
														<PersonIcon className={classes.IconSize} />
														<span className={classes.spanPosition}>Officers</span>
													</Typography>

													<CustomCard  >
														<Box >
															<ul >
																{
																	officers.map((data, i) => (
																		<li key={i}>
																			<Box >
																				<Link to={{
																					pathname: '/app/officers/details?id=' + data._id

																				}} onClick={() => this.redirectToOfficerDetails(data._id)}>


																					{data.name}<div className="separation-line hide-on-small-only"></div><span className={`${classes.spanColor} hide-on-small-only`} > {data.position} </span>

																				</Link>
																			</Box>
																		</li>
																	))
																}
															</ul>
														</Box>
													</CustomCard>
												</Grid>

											}
										</Grid>

										<Grid container spacing={3}>

											{this.state.searchData.length > 0 &&
												<Grid item xs={12} sm={this.state.officers.length > 0 ? 6 : 12} md={this.state.officers.length > 0 ? 6 : 12} >
													<Typography className="ml-1 margin-text" variant="body2">
														<WorkIcon className={classes.IconSize} />
														<span className={classes.spanPosition}>Branchen</span>
													</Typography>
													<CustomCard  >
														<Box >
															<ul >
																{
																	searchData.map((data, i) => (
																		<li key={i}>
																			<Box >
																				<Link to={{
																					pathname: '/app/companies/details?id=' + data.company_number

																				}} onClick={() => this.redirectToCompanyDetails(data.company_number)}>


																					{data.name}<div className="separation-line hide-on-small-only"></div><span className={`${classes.spanColor} hide-on-small-only`} > {data.all_attributes._registerArt} {data.all_attributes._registerNummer}</span>

																				</Link>
																			</Box>
																		</li>
																	))
																}
															</ul>
														</Box>
													</CustomCard>
												</Grid>
											}
											{this.state.officers.length > 0 &&

												<Grid item xs={12} sm={6} md={6} >
													<Typography className="ml-1 margin-text" variant="body2">
														<FindInPageIcon className={classes.IconSize} />
														<span className={classes.spanPosition}>Gelbe Seiten</span>
													</Typography>

													<CustomCard  >
														<Box >
															<ul >
																{
																	yellowPageData.map((data, i) => (
																		<li key={i}>
																			<Box >
																				<Link to={{
																					pathname: '/app/yellowPage/details/DE?id=' + data?.ID

																				}} onClick={() => this.redirectToYellowPageDetails(data?.ID)}>


																					{data?.title}<div className="separation-line hide-on-small-only"></div><span className={`${classes.spanColor} hide-on-small-only`} > {data?.address_attributes?.city} </span>

																				</Link>
																			</Box>
																		</li>
																	))
																}
															</ul>
														</Box>
													</CustomCard>
												</Grid>

											}
										</Grid>
									</Paper>
								</>
								: <></>}
					</Container>

				</div>
			</div>
		);
	}
}

export default withStyles(styles)(GlobalSearch);