/**
 * Auth Actions
 */

// jwt
import { userService } from '../_services';

import { NotificationManager } from 'react-notifications';

//Action types
import {
	LOGIN_EMAIL_CHANGED,
	LOGIN_PASSWORD_CHANGED,
	LOGOUT_USER,
	JWT_LOGIN_REQUEST,
	JWT_LOGIN_SUCCESS,
	JWT_LOGIN_FAILURE
} from './Types';


export const signinUserWithJwt = (user, history) => (dispatch) => {
	// return dispatch => {
	dispatch({ type: JWT_LOGIN_REQUEST, payload: user });

	userService.login(user.email, user.password)
		.then(
			user => {
				dispatch({ type: JWT_LOGIN_SUCCESS, payload: user });
				history.push('/');
				NotificationManager.success('Benutzer hat sich erfolgreich angemeldet');
			},
			error => {
				dispatch({ type: JWT_LOGIN_FAILURE, payload: error });
				NotificationManager.error('E-Mail oder Kennwort ungültig, Bitte versuchen Sie es erneut');
			}
		);
}
export const refreshToken = (history) => (dispatch) => {
	userService.refreshToken()
		.then(
			user => {
				console.log('user', user)
			},
			error => {
				console.log('error', error)
			}
		);
}
/**
 * Function to detect email changes
 */
export function onEmailChanged(email) {
	return {
		type: LOGIN_EMAIL_CHANGED,
		payload: email
	}
}

/**
 * Function to detect password change
 */
export function onPasswordChanged(password) {
	return {
		type: LOGIN_PASSWORD_CHANGED,
		payload: password
	}
}
/**
 * Redux action to logout user 
 */
export const hulkLogoutUser = () => (dispatch) => {
	dispatch({ type: LOGOUT_USER });
	localStorage.removeItem("user_id");
	
}