/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'

// Async component
import {
   AsyncUserSettingsComponent, AsyncUsersComponent,
} from 'routes';
import Companies from 'routes/Companies';
import Officers from 'routes/Officers';
import YellowPage from 'routes/YellowPage';
import Search from 'routes/Search/Search';
import Export from 'routes/Export';

export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },
   {
      path: 'users',
      component: AsyncUsersComponent
   },
   {
      path: 'companies',
      component: Companies
   },
   {
      path: 'officers',
      component: Officers
   },
   {
      path: 'yellowPage',
      component: YellowPage
   },
   {
      path: 'search',
      component: Search
   },
   {
      path: 'Export',
      component: Export
   }

]