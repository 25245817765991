export const DpmaService = {
    dpmaStatistic,
    dpmaBrand,
    dpmaDesign,
    dpmaPatent
};

function dpmaStatistic(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/dpma/statistic/${company_number}`, requestOptions).then(handleResponse);

}
function dpmaBrand(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/dpma/brand/${company_number}`, requestOptions).then(handleResponse);

}
function dpmaDesign(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/dpma/design/${company_number}`, requestOptions).then(handleResponse);

}
function dpmaPatent(company_number) {
    const requestOptions = {
        method: 'get',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/dpma/patent/${company_number}`, requestOptions).then(handleResponse);

}
function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.ok) {

            return data;
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    })
}
