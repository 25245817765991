/**
 * Courses Routing File
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
     AsyncWatchList,
     AsyncCompanyDetails,
     AsyncOfficer,
     AsyncNEWCompanies,
     AsyncCompanyLeiDetails
 } from 'routes';
 
 const Companies = ({ match }) => (
     <Switch>  
         <Route path={`${match.url}/watchList`} component={AsyncWatchList}></Route>		
         <Route path={`${match.url}/details`} component={AsyncCompanyDetails}></Route>	
         <Route path={`${match.url}/new`} component={AsyncNEWCompanies}></Route>		
         <Route path={`${match.url}/officer`} component={AsyncOfficer}></Route>		
         <Route path={`${match.url}/lei`} component={AsyncCompanyLeiDetails}></Route>

     </Switch>
 )
 export default Companies;