import React, { Component } from 'react';
import {
    Container,
} from '@material-ui/core';
import Select from "react-select";
import PriceSlider from './Components/PriceSlider';
import { CustomCard } from 'components/GlobalComponents';
import { withStyles, List, ListItem, ListItemText, AppBar, Tabs, Tab, Switch, Chip, ListItemIcon, Checkbox, Button, IconButton, TextField, InputAdornment, } from '@material-ui/core';
import { Grid, Box, Typography, Collapse } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import { AddressService, ApiHraService, YellowPageService } from '_services';
import { SmallTitleBar } from 'components/GlobalComponents';
import SwipeableViews from 'react-swipeable-views';
import SearchResult from './SearchResult'
import ReactFlagsSelect from "react-flags-select";
import SearchIcon from '@material-ui/icons/Search';
const styles = (theme) => ({
    tabsWrap: { boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)' },
    appWrap: {
        boxShadow: "none",
    },
    chipMargin: {
        margin: '0 5px',
        backgroundColor: "#fff"
    },
    margin: {
        marginLeft: 5,
        marginRight: 5
    },
    ListItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    Icon: {
        visibility: 'hidden'
    },
    placeholder: {
        textAlign: 'center'
    },
    paper: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        maxHeight: 800,
    },
    accordionBox: {
        backgroundColor: "#fff",
        borderRadius: '4px',
        marginBottom: "20px",
        border: "1px solid #ced4da",
        boxShadow: "0 0.267rem 1.067rem #6c757d1a",
        padding: '3px 0'
    },
    accordionItem: {
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '0',
        paddingBottom: '0',

    },
    accordionFilter: {
        paddingLeft: '17px',
        paddingRight: '6px',
        paddingTop: '0',
        paddingBottom: '0',

    },
    underline: {
        paddingTop: '0',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingBottom: '0',
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    customTabWrap: {
        position: 'relative',
        '& .custom-btn': {
            margin: '10px',
            color: theme.palette.text.secondary,
            backgroundColor: 'rgba(0,0,0,0) !important',
            borderRadius: 0,
            boxShadow: 'none !important',
            borderBottom: `2px solid rgba(0,0,0,0)`,
            transition: 'all 0.3s ease-out',
        },
        '& .active': {
            color: theme.palette.primary.main,
            borderBottom: `2px solid ${theme.palette.text.primary}`,
        },
        '& .drop-icon': {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 42,
            '& .res-dropdown': {
                width: '100%',
                height: 'auto',
                left: '-1px',
                top: 0,
                position: 'absolute',
                right: '-1px',
                zIndex: 9,
                border: '1px solid #d5d5d5',
                backgroundColor: theme.palette.background.paper,
                padding: '2px 0',
                '& .custom-btn': {
                    width: '100%',
                    display: 'none',
                    border: 0,
                },
                '& .active': {
                    display: 'block !important'
                },
            },
            '& .open': {
                '& .custom-btn': {
                    display: 'block !important',
                },
            },
            '& .drop-icon': {
                display: 'inline-block',
                position: 'absolute',
                right: 0,
                top: 3,
                zIndex: 9,
                display: 'block !important',
                width: 'auto !important',
                padding: 5
            },
        },
    },

});

const customStyles = {
    control: (base, state) => ({
        ...base,
        border: "1px solid #ced4da",
        boxShadow: "0 0.267rem 1.067rem #6c757d1a",

        "&:hover": {
            border: "1px solid #ced4da",
        },

    })
}
function TabPanel(props) {
    const { children, value, index, dir, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
            dir={dir}
            className="pad-12"
        >
            {value === index && <Box pb={4}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}
class Section extends Component {

    constructor() {
        super();
        this.state = {
            cityvalue: '',
            zip_codevalue: '',
            open: false,
            company_branches: [],
            checked: [],
            initialSelectedValue: '',
            company: {},
            city: {},
            search_key_word: "",
            expanded: 0,
            legalForm: [],
            positions: ['Unternehmen', 'Gelbe Seiten', 'Doktor'],
            selectedCountry: "DE",
            switch: ['wifi'],
            bundesland: [],
            capital: '',
            filter: 0,
            umsatz: '',
            exists_fields: [],
            YellowPgLoader: true,
            resultLoader: false,
            bkk: [
                { label: "Hebamme", value: "midwife" },
                { label: "clinics", value: "clinics" },
                { label: "doctors", value: "doctors" },
                { label: "care", value: "care" },

            ],
            bkk_field: [],

            company_legalForms: [
                { label: "Stiftung & Co. KGaA", value: "Stiftung & Co. KGaA" },
                { label: "Stiftung & Co. KG", value: "Stiftung & Co. KG" },
                { label: "Stiftung GmbH & Co. KG", value: "Stiftung GmbH & Co. KG" },
                { label: "SE & Co. KGaA", value: "SE & Co. KGaA" },
                { label: "GmbH & Co. KGaA", value: "GmbH & Co. KGaA" },
                { label: "GmbH & Co. KG", value: "GmbH & Co. KG" },
                { label: "GmbH & Co. OHG", value: "GmbH & Co. OHG" },
                { label: "GmbH", value: "GmbH" },
                { label: "AG & Co. KGaA", value: "AG & Co. KGaA" },
                { label: "AG & Co. KG", value: "AG & Co. KG" },
                { label: "AG & Co. OHG", value: "AG & Co. OHG" },
                { label: "REIT-AG", value: "REIT-AG" },
                { label: "InvAG", value: "InvAG" },
                { label: "VVaG", value: "VVaG" },
                { label: "gAG", value: "gAG" },
                { label: "AG", value: "AG" },
                { label: "Limited & Co. KG", value: "Limited & Co. KG" },
                { label: "UG (haftungsbeschränkt) & Co. KG", value: "UG (haftungsbeschränkt) & Co. KG" },
                { label: "UG (haftungsbeschränkt)", value: "UG (haftungsbeschränkt)" },
                { label: "KGaA", value: "KGaA" },
                { label: "KG", value: "KG" },
                { label: "OHG", value: "OHG" },
                { label: "GbR", value: "GbR" },
                { label: "PartG mbB", value: "PartG mbB" },
                { label: "PartG", value: "PartG" },
                { label: "AöR", value: "AöR" },
                { label: "eg", value: "eg" },
                { label: "e.K.", value: "e.K." },
                { label: "e.V.", value: "e.V." },
                { label: "KdöR", value: "KdöR" },
                { label: "EWIV", value: "EWIV" },
                { label: "SE", value: "SE" },
                { label: "SCE", value: "SCE" },
            ].sort(function (a, b) {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            }),
            resultData: [],
            bundeslands: [
                { label: 'Baden-Württemberg', value: 'Baden-Württemberg', short_name: "bw" },
                { label: 'Brandenburg', value: 'Brandenburg', short_name: "br" },
                { label: 'Mecklenburg-Vorp', value: 'Mecklenburg-Vorp', short_name: "mv" },
                { label: 'Sachsen-Anhalt', value: 'Sachsen-Anhalt', short_name: "st" },
                { label: 'Niedersachsen', value: 'Niedersachsen', short_name: "ni" },
                { label: 'Rheinland-Pfalz', value: 'Rheinland-Pfalz', short_name: "rp" },
                { label: 'Schleswig-Holstein', value: 'Schleswig-Holstein', short_name: "sh" },
                { label: 'Nordrhein-Westf', value: 'Nordrhein-Westf', short_name: "nw" },
                { label: 'Saarland', value: 'Saarland', short_name: "sl" },
                { label: 'Berlin', value: 'Berlin', short_name: "be" },
                { label: 'Bremen', value: 'Bremen', short_name: "hb" },
                { label: 'Hessen', value: 'Hessen', short_name: "he" },
                { label: 'Bayern', value: 'Bayern', short_name: "by" },
                { label: 'Sachsen', value: 'Sachsen', short_name: "sn" },
                { label: 'Thüringen', value: 'Thüringen', short_name: "th" },
                { label: "Hamburg", value: "Hamburg", short_name: "hh" }
            ]


        }
    }


    handleChange = (event) => {
        this.setState({ expanded: event });
    };

    getCompanyBranches() {
        ApiHraService.getcompanyBranches().then(result => {
            let objects = []
            result.map(element => {
                objects.push({
                    label: element.wz2008_key + '- ' + element.titel,
                    value: element.children
                })
            })
            this.setState({ company_branches: objects })
        })
    }
    handleToggle(value) {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = this.state.checked;
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked
        })
    };
    handleChangeTab = (event, value) => {
        this.setState({ filter: value });
        this.state.positions.map((data, index) => {
            if (value == index)
                this.setState({ initialSelectedValue: data })
        })

    };
    handleClick(value) {
        this.setState({
            [value]: !this.state[value]
        });
    }

    componentDidMount() {
        this.getCompanyBranches()

    }


    advancedSearch() {
        let object = JSON.stringify({
            "country": this.state.selectedCountry,
            "key_word": this.state.search_key_word,
            "existFields": this.state.exists_fields,
            "city": this.state.cityvalue,
            "zip_code": this.state.zip_codevalue
        })
        this.setState({ resultLoader: true, YellowPgLoader: false })
        YellowPageService.advancedSearch(object).then(result => {
            this.setState({ resultData: result.YellowPageData, resultLoader: false })
            if (result.YellowPageData.length == 0)
                this.setState({ YellowPgLoader: false })
        })
    }
    getCitiesList(bundesland, city_name) {
        AddressService.getCitiesList(bundesland, city_name).then(result => {
            let object = {}
            let citiesList = []
            result.cities.map(element => {
                object = { label: element.ort, value: element.ort }
                citiesList.push(object)
            })
            this.setState({ cities: citiesList })
        })
    }


    handleChangeCompanyData = (e, type = null) => {

        if (type === "bundesland")
            this.setState({ bundesland: e })
        else if (type === "lagalForm")
            this.setState({ legalForm: e })
        else if (type == "Branche") {
            this.setState({ branche: e })
        }
        else if (type == "Bkk") {
            this.setState({ bkk_field: e })
        }

        else
            this.setState({ [e.target.name]: e.target.value })


    }
    renderExistsFields() {
        const { classes } = this.props;
        return (
            <List
                className={classes.accordionBox}
            >
                <ListItem
                    button onClick={() => this.handleClick('openData')} className={classes.accordionFilter}>
                    <ListItemText primary={<Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                        <i className={`material-icons accordionIcon`}>list</i> Verfügbarkeit der Daten
                    </Typography>} />
                    {this.state.openData ? <ArrowDropUpIcon className='text-secondary' /> : <ArrowDropDownIcon className='text-secondary' />}
                </ListItem>
                <Collapse in={this.state.openData} timeout="auto" unmountOnExit>
                    <List dense >

                        <ListItem button className='height-item'>
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    onChange={this.handleToggle('phone_number')}
                                    checked={this.state.exists_fields.indexOf('phone_number') !== -1}
                                /> </ListItemIcon>
                            <ListItemText primary={'Telefonnummer'} />


                        </ListItem>
                        <ListItem button className='height-item'>
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    onChange={this.handleToggle('email')}
                                    checked={this.state.exists_fields.indexOf('email') !== -1}
                                />
                            </ListItemIcon>
                            <ListItemText primary={'E-Mail'} />

                        </ListItem>
                        <ListItem button className='height-item'>
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    onChange={this.handleToggle('website')}
                                    checked={this.state.exists_fields.indexOf('website') !== -1}

                                />
                            </ListItemIcon>
                            <ListItemText primary={'website'} />

                        </ListItem>


                    </List>
                </Collapse>
            </List>
        )
    }
    handleToggle = value => () => {
        const currentIndex = this.state.exists_fields.indexOf(value);
        const newChecked = this.state.exists_fields;

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({ exists_fields: newChecked });
    };
    handleCountryChange(code) {
        this.setState({ selectedCountry: code });
    }


    handleSwitch = (value) => {
        const currentIndex = this.state.switch.indexOf(value);
        const newChecked = [...this.state.switch];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ switch: newChecked });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <div className="tables-wrapper search-table-wrap">
                <SmallTitleBar title={"Suchergebnisse"} />
                <Box className={classes.tabsWrap} bgcolor="background.paper">
                    <Container>
                        <AppBar position="static" className={classes.appWrap}>
                            <Tabs value={this.state.filter}
                                onChange={this.handleChangeTab}
                                indicatorColor='primary'
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="scrollable auto tabs example"
                                className={`${classes.toolbar} contact-grid-tabs`}
                            >
                                {this.state.positions.map((element, index) => {
                                    return (
                                        <Tab label={element} {...a11yProps(index)} />)
                                })}
                            </Tabs>
                        </AppBar>
                    </Container>
                </Box>
                <Container maxWidth="lg">
                    <Box px={{ xs: '12px', lg: 0 }} className="page-space">

                        <Grid container spacing={3} className="res-custom-table">
                            <Grid item xs={12} sm={12} md={4} className='sticky-side'>
                                <CustomCard cardClasses="preview-panel box-filter "  >

                                    <Box className="welcome-note-list filter-table ">
                                        <Box>
                                            <i className={`material-icons `}>tune</i>
                                        </Box>
                                        <Typography variant="h5" color="textSecondary"><span className=" text-dark"> Filter</span></Typography>

                                    </Box>
                                    <SwipeableViews
                                        index={this.state.filter}
                                    >
                                        <TabPanel >

                                            <>
                                                <Box mb={2}>
                                                    <Select
                                                        placeholder={<div className="select-placeholder-text">Rechtsform</div>}
                                                        styles={customStyles}
                                                        isMulti
                                                        isSearchable={true}
                                                        options={this.state.company_legalForms}
                                                        value={this.state.legalForm}
                                                        onChange={(e) => this.handleChangeCompanyData(e, 'lagalForm')}

                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Select
                                                        placeholder={<div className="select-placeholder-text">Branche</div>}
                                                        styles={customStyles}
                                                        isMulti
                                                        options={this.state.company_branches}
                                                        value={this.state.branche}
                                                        onChange={(e) => this.handleChangeCompanyData(e, 'Branche')}

                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Select
                                                        placeholder={<div className="select-placeholder-text">Bundesländer</div>}
                                                        styles={customStyles}
                                                        isMulti
                                                        options={this.state.bundeslands}
                                                        value={this.state.bundesland}
                                                        onChange={(e) => this.handleChangeCompanyData(e, 'bundesland')}

                                                    />
                                                </Box>

                                                <List
                                                    className={classes.accordionBox}
                                                >
                                                    <ListItem
                                                        button onClick={() => this.handleClick('open')} className={classes.accordionItem}>
                                                        <ListItemText primary={<Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                                                            <i className={`material-icons accordionIcon`}>euro</i>  Capital
                                                        </Typography>} />
                                                        {this.state.open ? <ArrowDropUpIcon className='text-secondary' /> : <ArrowDropDownIcon className='text-secondary' />}
                                                    </ListItem>
                                                    <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                                        <div className="bg-white filter-widget price-fil-widget">

                                                            <PriceSlider />
                                                        </div>
                                                    </Collapse>
                                                </List>
                                                <List
                                                    className={classes.accordionBox}
                                                >
                                                    <ListItem
                                                        button onClick={() => this.handleClick('open')} className={classes.accordionItem}>
                                                        <ListItemText primary={<Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                                                            <i className={`material-icons accordionIcon`}>euro</i>  Umsatz
                                                        </Typography>} />
                                                        {this.state.open ? <ArrowDropUpIcon className='text-secondary' /> : <ArrowDropDownIcon className='text-secondary' />}
                                                    </ListItem>
                                                    <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                                        <div className="bg-white filter-widget price-fil-widget">

                                                            <PriceSlider />
                                                        </div>
                                                    </Collapse>
                                                </List>
                                                <List
                                                    className={classes.accordionBox}
                                                >
                                                    <ListItem
                                                        button onClick={() => this.handleClick('openData')} className={classes.accordionItem}>
                                                        <ListItemText primary={<Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                                                            <i className={`material-icons accordionIcon`}>list</i> Verfügbarkeit der Daten
                                                        </Typography>} />
                                                        {this.state.openData ? <ArrowDropUpIcon className='text-secondary' /> : <ArrowDropDownIcon className='text-secondary' />}
                                                    </ListItem>
                                                    <Collapse in={this.state.openData} timeout="auto" unmountOnExit>
                                                        <List dense >

                                                            <ListItem button className='height-item'>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="end"
                                                                        color="primary"
                                                                        onChange={this.handleToggle('phone_number')}
                                                                        checked={this.state.exists_fields.indexOf('phone_number') !== -1}
                                                                    /> </ListItemIcon>
                                                                <ListItemText primary={'Telefonnummer'} />


                                                            </ListItem>
                                                            <ListItem button className='height-item'>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="end"
                                                                        color="primary"
                                                                        onChange={this.handleToggle('email')}
                                                                        checked={this.state.exists_fields.indexOf('E-Mail') !== -1}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary={'E-Mail'} />

                                                            </ListItem>
                                                            <ListItem button className='height-item'>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="end"
                                                                        color="primary"
                                                                        onChange={this.handleToggle('website')}
                                                                        checked={this.state.exists_fields.indexOf('website') !== -1}

                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary={'website'} />

                                                            </ListItem>
                                                            <ListItem button className='height-item'>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="end"
                                                                        color="primary"
                                                                        onChange={this.handleToggle('Management')}
                                                                        checked={this.state.exists_fields.indexOf('Management') !== -1}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary={'Management'} />

                                                            </ListItem>

                                                        </List>
                                                    </Collapse>
                                                </List>

                                            </>
                                        </TabPanel>

                                        <TabPanel >
                                            <>
                                                <Box mb={2}>
                                                    <ReactFlagsSelect
                                                        selected={this.state.selectedCountry}
                                                        onSelect={(code) => this.handleCountryChange(code)}
                                                        countries={["DE", "AT", "CH"]}
                                                        customLabels={{ DE: "Deutschland", AT: "Österreich", CH: "Schweiz" }}
                                                        defaultCountry="DE"
                                                        placeholder="Deutschland"
                                                    />
                                                </Box>
                                                <Box mb={2} className={classes.accordionBox}>
                                                    <TextField
                                                        name="search_key_word"
                                                        className="searchInput"
                                                        fullWidth
                                                        id="standard-basic"
                                                        InputProps={{
                                                            classes,
                                                            endAdornment: (
                                                                <InputAdornment style={{ position: 'absolute' }}>
                                                                    <IconButton>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        placeholder="Unternehmen suchen & finden"
                                                        onChange={(e) => this.handleChangeCompanyData(e)}
                                                        value={this.state.search_key_word}
                                                    />
                                                </Box>


                                                <Box mb={2} className={classes.accordionBox}>
                                                    <TextField
                                                        className="searchInput"
                                                        fullWidth
                                                        InputProps={{
                                                            classes,
                                                            endAdornment: (
                                                                <InputAdornment style={{ position: 'absolute' }}>
                                                                    <IconButton>
                                                                        <LocationOnOutlinedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        placeholder="Postleitzahl*"
                                                        onChange={(e) => this.handleChangeCompanyData(e)}
                                                        name='zip_codevalue'
                                                        value={this.state.zip_codevalue}
                                                    />
                                                </Box>
                                                <Box mb={2} className={classes.accordionBox}>
                                                    <TextField
                                                        className="searchInput"
                                                        fullWidth
                                                        InputProps={{
                                                            classes,
                                                            endAdornment: (
                                                                <InputAdornment style={{ position: 'absolute' }}>
                                                                    <IconButton>
                                                                        <LocationCityOutlinedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        name='cityvalue'

                                                        placeholder="Stadt *"
                                                        onChange={(e) => this.handleChangeCompanyData(e, 'cityvalue')}
                                                        value={this.state.cityvalue}
                                                    />
                                                </Box>


                                                {this.renderExistsFields()}

                                                <Button variant="contained" className='primary-bg-btn compose-btn' onClick={() => this.advancedSearch()} disabled={(this.state.search_key_word === "")} >
                                                    search
                                                </Button>
                                            </>
                                        </TabPanel>
                                        <TabPanel >

                                            <>
                                                <Box mb={2}>
                                                    <Select
                                                        placeholder={<div className="select-placeholder-text">Alle</div>}
                                                        styles={customStyles}
                                                        isSearchable={true}
                                                        options={this.state.bkk}
                                                        value={this.state.bkk_field}
                                                        onChange={(e) => this.handleChangeCompanyData(e, 'Bkk')}

                                                    />
                                                </Box>
                                                {this.state.bkk_field.value === "midwife" ?
                                                    <>
                                                        <List
                                                            className={classes.accordionBox}
                                                        >
                                                            <ListItem
                                                                button onClick={() => this.handleClick('openData')} className={classes.accordionItem}>
                                                                <ListItemText primary={<Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                                                                    <i className={`material-icons accordionIcon`}>list</i> Verfügbarkeit der Daten
                                                                </Typography>} />
                                                                {this.state.openData ? <ArrowDropUpIcon className='text-secondary' /> : <ArrowDropDownIcon className='text-secondary' />}
                                                            </ListItem>
                                                            <Collapse in={this.state.openData} timeout="auto" unmountOnExit>
                                                                <List dense >

                                                                    <ListItem button className='height-item'>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="end"
                                                                                color="primary"
                                                                                onChange={this.handleToggle('phone_number')}
                                                                                checked={this.state.exists_fields.indexOf('phone_number') !== -1}
                                                                            /> </ListItemIcon>
                                                                        <ListItemText primary={'Telefonnummer'} />


                                                                    </ListItem>
                                                                    <ListItem button className='height-item'>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="end"
                                                                                color="primary"
                                                                                onChange={this.handleToggle('email')}
                                                                                checked={this.state.exists_fields.indexOf('E-Mail') !== -1}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={'E-Mail'} />

                                                                    </ListItem>

                                                                </List>
                                                            </Collapse>
                                                        </List>
                                                        <Box mb={2}>
                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Schwangerenvorsorge"
                                                                clickable
                                                                color="primary"
                                                                variant="outlined"
                                                                className={classes.chipMargin}
                                                            />
                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Hausgeburt"
                                                                clickable
                                                                color="primary"
                                                                className={classes.chipMargin}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                        <Box mb={2}>


                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Geburtshausgeburt"
                                                                clickable
                                                                className={classes.chipMargin}
                                                                color="primary"
                                                                variant="outlined"
                                                            />
                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Beleggeburt"
                                                                clickable
                                                                className={classes.chipMargin}
                                                                color="primary"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Wochenbettbetreuung"
                                                                clickable
                                                                color="primary"
                                                                variant="outlined"
                                                                className={classes.chipMargin}
                                                            />
                                                            <Chip
                                                                icon={<Switch
                                                                    color="primary"
                                                                    edge="end"
                                                                    onChange={() => this.handleSwitch('wifi')}
                                                                    checked={this.state.switch.indexOf('wifi') !== -1}
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                />}
                                                                label="Kursec"
                                                                clickable
                                                                color="primary"
                                                                className={classes.chipMargin}
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                    </>
                                                    : this.state.bkk_field.value === "clinics" ?
                                                        <>
                                                            {this.renderExistsFields()}
                                                            <Box mb={2} className={classes.accordionBox}>
                                                                <TextField
                                                                    className="searchInput"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        classes,
                                                                        endAdornment: (
                                                                            <InputAdornment style={{ position: 'absolute' }}>
                                                                                <IconButton>
                                                                                    <LocationCityOutlinedIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    placeholder="Stadt *"

                                                                    value={value}
                                                                />
                                                            </Box>

                                                            <Box mb={2} className={classes.accordionBox}>
                                                                <TextField
                                                                    className="searchInput"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        classes,
                                                                        endAdornment: (
                                                                            <InputAdornment style={{ position: 'absolute' }}>
                                                                                <IconButton>
                                                                                    <LocationOnOutlinedIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    placeholder="Postleitzahl*"

                                                                    value={value}
                                                                />
                                                            </Box>
                                                            <Box mb={2} className={classes.accordionBox}>
                                                                <TextField
                                                                    className="searchInput"
                                                                    fullWidth
                                                                    type="number"

                                                                    id="standard-basic"
                                                                    InputProps={{
                                                                        classes,
                                                                        endAdornment: (
                                                                            <InputAdornment style={{ position: 'absolute' }}>
                                                                                <IconButton>
                                                                                    <HotelOutlinedIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    placeholder="Number of BED"

                                                                    value={value}
                                                                />
                                                            </Box>
                                                            <Box mb={2} className={classes.accordionBox}>
                                                                <TextField
                                                                    className="searchInput"
                                                                    fullWidth
                                                                    id="standard-basic"
                                                                    type="number"
                                                                    InputProps={{
                                                                        classes,
                                                                        endAdornment: (
                                                                            <InputAdornment style={{ position: 'absolute' }}>
                                                                                <IconButton>
                                                                                    <LocalHospitalOutlinedIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    placeholder="Number of case"

                                                                    value={value}
                                                                />
                                                            </Box>

                                                        </>
                                                        : this.state.bkk_field.value === "doctors" ?
                                                            <>
                                                                {this.renderExistsFields()}


                                                                <Box mb={2} className={classes.accordionBox}>
                                                                    <TextField
                                                                        className="searchInput"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            classes,
                                                                            endAdornment: (
                                                                                <InputAdornment style={{ position: 'absolute' }}>
                                                                                    <IconButton>
                                                                                        <LocationOnOutlinedIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        placeholder="Postleitzahl*"

                                                                        value={value}
                                                                    />
                                                                </Box>
                                                                <Box mb={2} className={classes.accordionBox}>
                                                                    <TextField
                                                                        className="searchInput"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            classes,
                                                                            endAdornment: (
                                                                                <InputAdornment style={{ position: 'absolute' }}>
                                                                                    <IconButton>
                                                                                        <LocationCityOutlinedIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        placeholder="Stadt *"

                                                                        value={value}
                                                                    />
                                                                </Box>
                                                                <Box mb={2}>


                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="Family doctor"
                                                                        clickable
                                                                        className={classes.chipMargin}
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="group practice"
                                                                        clickable
                                                                        className={classes.chipMargin}
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                </Box>
                                                                <Box mb={2}>
                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="Health insurance"
                                                                        clickable
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        className={classes.chipMargin}
                                                                    />
                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="Home visits"
                                                                        clickable
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        className={classes.chipMargin}
                                                                    />

                                                                </Box>
                                                                <Box mb={2}>


                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="Family doctor"
                                                                        clickable
                                                                        className={classes.chipMargin}
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                    <Chip
                                                                        icon={<Switch
                                                                            color="primary"
                                                                            edge="end"
                                                                            onChange={() => this.handleSwitch('wifi')}
                                                                            checked={this.state.switch.indexOf('wifi') !== -1}
                                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                                        />}
                                                                        label="group practice"
                                                                        clickable
                                                                        className={classes.chipMargin}
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                </Box>

                                                            </>
                                                            : this.state.bkk_field.value === "care" ?
                                                                <>
                                                                    {this.renderExistsFields()}
                                                                    <Box mb={2} className={classes.accordionBox}>
                                                                        <TextField
                                                                            className="searchInput"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                classes,
                                                                                endAdornment: (
                                                                                    <InputAdornment style={{ position: 'absolute' }}>
                                                                                        <IconButton>
                                                                                            <LocationCityOutlinedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            placeholder="Stadt *"

                                                                            value={value}
                                                                        />
                                                                    </Box>
                                                                    <Box mb={2} className={classes.accordionBox}>
                                                                        <TextField
                                                                            className="searchInput"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                classes,
                                                                                endAdornment: (
                                                                                    <InputAdornment style={{ position: 'absolute' }}>
                                                                                        <IconButton>
                                                                                            <MarkunreadMailboxOutlinedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            placeholder="Strasse *"

                                                                            value={value}
                                                                        />
                                                                    </Box>
                                                                    <Box mb={2} className={classes.accordionBox}>
                                                                        <TextField
                                                                            className="searchInput"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                classes,
                                                                                endAdornment: (
                                                                                    <InputAdornment style={{ position: 'absolute' }}>
                                                                                        <IconButton>
                                                                                            <LocationOnOutlinedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            placeholder="Postleitzahl*"

                                                                            value={value}
                                                                        />
                                                                    </Box>
                                                                </>
                                                                : <></>
                                                }
                                            </>

                                        </TabPanel>
                                    </SwipeableViews>

                                </CustomCard>
                            </Grid>
                            <SearchResult resultData={this.state.resultData} country={this.state.selectedCountry} initialSelectedValue={this.state.initialSelectedValue} YellowPgLoader={this.state.YellowPgLoader} resultLoader={this.state.resultLoader} />

                        </Grid>
                    </Box></Container>


            </div >
        );
    }
}

export default withStyles(styles)(Section);
